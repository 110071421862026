import React, { Component } from "react";
import { Link, Image, withSitecoreContext, isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import { withTranslation } from "react-i18next";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import config from "../../temp/config";

import { throttle, isMobile, isNotAtTop } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { isAppMobile, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import { PushDataLayerUserId, getGA4WindowDatas, trackGA4PageView, addGA4SessionData } from "../../utilities/FrontFunctions/AoxTrackingFunctions";
import { badges } from "../../data/badges";
import ExpertiseBadgeModal from "../Modal/ExpertiseBadgeModal";

const axios = require("axios");

class MainHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isMobile: isMobile(),
			modeMini: isNotAtTop(),
			marqueIndex: -1,
			logoIndex: 0,
			dataLevel: 3,
			dataId: "",
			dataLabel: "",
			labelMenuMobile: "Menu",
			SecondNavCaf: {
				userName: "-",
				percentExpert: "-",
			},
			serversuccessLogOut: false,
			UrlLogOut: `${config.sitecoreApiHost}/apiaox/Call/LogOut`,
			apiDatasUserBadgesNotDisplayed: [],
			newBadge: false,
			newBadgeFade: false,
		};
		this.handleWindowResizeMainHeader = this.handleWindowResizeMainHeader.bind(this);
		this.throttleWindowResizeMainHeader = throttle(this.handleWindowResizeMainHeader, 200);
		this.handleWindowScrollMainHeader = this.handleWindowScrollMainHeader.bind(this);
		this.throttleWindowScrollMainHeader = throttle(this.handleWindowScrollMainHeader, 200);
		this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
		this.handleNextNavMobile = this.handleNextNavMobile.bind(this);
		this.handlePrevNavMobile = this.handlePrevNavMobile.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
		this.handleOpenModalBadge = this.handleOpenModalBadge.bind(this);
		this.bPushDataLayerUserId_Done = false;
		this.bPushTrackPage_Done = false;
	}
	componentDidMount() {
		const { CurrentPage, GetBreadCrumb, CurrentUser } = this.props.fields.data;

		window.addEventListener("resize", this.throttleWindowResizeMainHeader);
		window.addEventListener("scroll", this.throttleWindowScrollMainHeader);
		setTimeout(() => {
			if (document.body.classList.contains("pg_brand")) {
				this.setState({
					dataLevel: 3,
				});
			} else {
				this.setState({
					dataLevel: 1,
				});
			}
		}, 0);

		getGA4WindowDatas(CurrentUser, CurrentPage, GetBreadCrumb).then((resultDatas) => {
			window.trackDatas = resultDatas;
			addGA4SessionData(resultDatas);
			trackGA4PageView(window.trackDatas);
		});

		if (CurrentUser?.hasBadgeToDisplay) {
			postApiDatas(`/apiaox/Call/UserBadgesNotDisplayed`, {}).then((res) => {
				if (res.IsValid) {
					this.setState({ apiDatasUserBadgesNotDisplayed: res.Value, ModalBadgeIndex: -1, newBadge: true });
					this.handleOpenModalBadge();
				}
			});
		}
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.throttleWindowResizeMainHeader);
		window.removeEventListener("scroll", this.throttleWindowScrollMainHeader);
	}
	handleWindowResizeMainHeader() {
		this.setState({
			isMobile: isMobile(),
		});
	}
	handleWindowScrollMainHeader() {
		this.setState({
			modeMini: isNotAtTop(),
		});
	}
	toggleMenuMobile() {
		document.body.classList.toggle("menu-mobile_is-opened");
	}
	handleNextNavMobile(e, level, index) {
		this.setState({
			dataLevel: level,
			marqueIndex: index,
			dataId: e.currentTarget.getAttribute("data-id"),
			dataLabel: e.currentTarget.getAttribute("data-label"),
		});
		window.scrollTo(0, 0);
	}
	handlePrevNavMobile(level) {
		if (level > 1) {
			if (level === 3) {
				this.setState({
					dataId: "marque",
					dataLabel: this.props.t("EspacesMarques_MenuMob"),
				});
			}
			if (level === 2) {
				this.setState({
					dataId: "",
					dataLabel: "",
				});
			}
			this.setState({
				dataLevel: level - 1,
			});
		}
		window.scrollTo(0, 0);
	}
	handleLogOut() {
		axios({
			method: "POST",
			url: this.state.UrlLogOut,
			withCredentials: true,
		})
			.then((result) => {
				if (result.data.IsValid) {
					this.setState({
						serversuccessLogOut: true,
					});
				} else {
					alert(result.data.ExceptionMessage);
				}
			})
			.catch((err) => {
				console.log(err);
				alert(err);
			});
	}
	handleOpenModalBadge() {
		if (this.state.apiDatasUserBadgesNotDisplayed) {
			setTimeout(() => {
				this.setState({ ModalBadgeIndex: this.state.ModalBadgeIndex + 1 });
				if (this.state.ModalBadgeIndex >= 0 && this.state.ModalBadgeIndex < this.state.apiDatasUserBadgesNotDisplayed.UserBadges.length) {
					this["ExpertiseBadgeModal" + this.state.ModalBadgeIndex].openModal();
				}
			}, 1000);
		}
	}
	render() {
		const { MainNavCaf, navCompte, SecondNavMobile, AccueilCaf, PageContact, CurrentPage, GetAncesor, CurrentUser } = this.props.fields.data;
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

		if (CurrentUser && !this.bPushDataLayerUserId_Done && !isAppMobile()) {
			PushDataLayerUserId(CurrentUser.userkey).then((result) => {
				this.bPushDataLayerUserId_Done = result;
			});
		}

		var sClassForPage = oCurrentItemFields != null ? oCurrentItemFields["Class on page"].value : "";
		if (oCurrentItemFields && oCurrentItemFields["Brand CssClass"]) {
			sClassForPage = sClassForPage + (sClassForPage.length > 0 ? " " : "") + oCurrentItemFields["Brand CssClass"].value;
		}
		if (CurrentPage && CurrentPage.getbrand) {
			sClassForPage = sClassForPage + (sClassForPage.length > 0 ? " " : "") + CurrentPage.getbrand.brandCssClass.value;
		}
		if (isExperienceEditorActive() || this.props.sitecoreContext.pageState === "edit" || this.props.sitecoreContext.pageState === "preview") {
			sClassForPage += " ExperienceEditorActive ";
		}
		if (isAppMobile()) {
			sClassForPage += " hide-for-mobile-app ";
		}

		var iCurrentIndexMenu = 0;
		if (GetAncesor && GetAncesor.ancestors.length > 0) {
			var sClassName = GetAncesor.ancestors[0].brandCssClass.value.replace("var_", "");
			iCurrentIndexMenu = MainNavCaf.children[0].SubMenu.findIndex((m) => m.cSSClass.value === sClassName);
		}

		if (this.state.serversuccessLogOut) {
			return <Redirect to="/" />;
		}

		return (
			<>
				<React.Fragment>
					<Helmet>{sClassForPage !== "" && <body className={sClassForPage} />}</Helmet>
				</React.Fragment>

				{CurrentUser && !isAppMobile() && (
					<header className={`main_header${this.state.modeMini ? " mode_mini" : ""}`}>
						<div className="ctn_nav-caf">
							<div className="str_container">
								<h6 className="logo-caf">
									<a href={AccueilCaf && AccueilCaf.url}>
										<img src={require("../../assets/images/v_logo-pa.svg")} alt={this.props.t("AltLogo")} />
									</a>
								</h6>

								{this.state.isMobile ? (
									<>
										<button type="button" className="bt_burger" id="tutorial_step_4" onClick={this.toggleMenuMobile}>
											<span></span>
										</button>
										<div className={`obj_avatar-expertise is_bronze${this.state.newBadge ? " new_badge" : ""}${this.state.newBadgeFade ? " fade-in" : ""}`}>
											<div className="border_expertise"></div>
											<div className="ctn_expertise">
												<div className="ctn_visuel">
													{CurrentUser.userimg !== "" ? (
														<img src={CurrentUser.userimg} alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
													) : (
														<img src={require("../../assets/images/v_avatar-xl.png")} alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
													)}
												</div>
											</div>
											<div className="ctn_badge">
												<img src={require("../../assets/images/badge_png/Badge_PngCaf.png")} alt="" />
											</div>
										</div>
									</>
								) : (
									<>
										<nav className="main_nav-caf">
											<ul className="lvl_01">
												{MainNavCaf.children.map(
													(MainNavCafItem, index) =>
														(MainNavCafItem.id !== "89273D34AC6D42F5AFF21F805D7B0923" || CurrentUser.isChallenge) && (
															<li key={index} className={MainNavCafItem.hasChildren ? "has-sub-level" : ""}>
																{MainNavCafItem.hasChildren ? (
																	<>
																		<span>{MainNavCafItem.destination.text}</span>
																		<div className={`sub_nav-caf ${MainNavCafItem.cSSClass.value}`}>
																			<div className="str_container">
																				{MainNavCafItem.SubMenu.map((SubMenuItem, index) => (
														                            (SubMenuItem.id !== "4DC441D2C3D84E7AA1DE1E8191358FE1" || CurrentUser.isManager) && (
                                                                                        <ul key={index}>
                                                                                            <li className={SubMenuItem.cSSClass.value}>
                                                                                                <Link field={SubMenuItem.destination.jss}></Link>
                                                                                            </li>
                                                                                            {SubMenuItem.SubMenu.map(
                                                                                                (SubMenuItem, index) => (
                                                                                                    SubMenuItem.destination.url !== "" &&
                                                                                                    <li key={index}>
                                                                                                        <Link field={SubMenuItem.destination.jss}></Link>
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    )
																				))}
																			</div>
																		</div>
																	</>
																) : (
																	<span>
																		<Link field={MainNavCafItem.destination.jss}></Link>
																	</span>
																)}
															</li>
														)
												)}
											</ul>
										</nav>
										<nav className="second_nav-caf" id="tutorial_step_4">
											<ul className="lvl_01">
												<li className="li_compte">
													<span isauthenticated={CurrentUser.isAuthenticated && CurrentUser.isAuthenticated.toString()} username={CurrentUser.username}>
														{this.props.t("Bienvenue")} <strong>{CurrentUser.firstname}</strong>
													</span>
													<ul className="lvl_02">
														{navCompte.children.map((navCompteItem, index) => (
															<li key={index}>
																<Link field={navCompteItem.destination.jss}></Link>
															</li>
														))}
														{/*eslint-disable */}
														<li>
															<a onClick={() => this.handleLogOut()}>{this.props.t("MenuDeconnexion")}</a>
														</li>
														{/*eslint-enable */}
													</ul>
												</li>

												<li className="li_expertise">
													{/* TODO SGU style newbadge / bronze */}
													<div className="border_expertise"></div>
													<div className="ctn_expertise">
														<div className="ctn_visuel">
															{CurrentUser.userimg !== "" ? (
																<img src={CurrentUser.userimg} alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
															) : (
																<img src={require("../../assets/images/v_avatar-xl.png")} alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
															)}
														</div>
														<div className="ctn_pourcent">
															<span>
																{CurrentUser.userscore <= 0 ? (
																	<>
																		<strong>0</strong>pt
																	</>
																) : (
																	<>
																		<strong>{CurrentUser.userscore}</strong>pts
																	</>
																)}
															</span>
														</div>
													</div>
													<div className="ctn_badge">
														<img src={require("../../assets/images/badge_png/Badge_PngCaf.png")} alt="" />
													</div>
												</li>
											</ul>
										</nav>
									</>
								)}
							</div>
						</div>

						<div className="ctn_nav-marque">
							<div className="str_container">
								<h6 className="logo-marque">
									{MainNavCaf &&
										MainNavCaf.children[0].SubMenu[iCurrentIndexMenu] &&
										MainNavCaf.children[0].SubMenu[iCurrentIndexMenu].destination &&
										MainNavCaf.children[0].SubMenu[iCurrentIndexMenu].destination.targetItem &&
										MainNavCaf.children[0].SubMenu[iCurrentIndexMenu].destination.targetItem.logo && (
											<Link field={MainNavCaf.children[0].SubMenu[iCurrentIndexMenu].destination.jss}>
												<Image field={MainNavCaf.children[0].SubMenu[iCurrentIndexMenu].destination.targetItem.logo.jss}></Image>
											</Link>
										)}
								</h6>
								{!this.state.isMobile && (
									<nav className="main_nav-marque">
										<ul className="lvl_01">
											{MainNavCaf.children[0].SubMenu[iCurrentIndexMenu] &&
												MainNavCaf.children[0].SubMenu[iCurrentIndexMenu].SubMenu.map(
													(SubMenuItem, index) =>
														SubMenuItem.destination.url !== "" && (
															<li key={index} className={SubMenuItem.highlighting.boolValue ? "var_marque" : ""}>
																<Link field={SubMenuItem.destination.jss}></Link>
															</li>
														)
												)}
										</ul>
									</nav>
								)}
							</div>
						</div>

						{this.state.isMobile && (
							<div className="ctn_nav-mobile" data-level={this.state.dataLevel} data-id={this.state.dataId} data-label={this.state.dataLabel}>
								<button
									className="bt_title"
									type="button"
									data-level={this.state.dataLevel}
									data-id={this.state.dataId}
									data-label={this.state.dataLabel}
									onClick={() => this.handlePrevNavMobile(this.state.dataLevel)}
								>
									{this.state.dataLevel === 1 && this.state.labelMenuMobile}

									{this.state.dataLevel > 1 && this.state.dataLabel === "" && GetAncesor && GetAncesor.ancestors.length > 0 && GetAncesor.ancestors[0].pageTitle.value}
									{this.state.dataLevel > 1 && this.state.dataLabel !== "" && this.state.dataLabel}
								</button>
								<div className="ctn_panels">
									<div className="ctn_panel level-1">
										{this.state.dataLevel === 1 && (
											<>
												<ul className="main_nav-mobile">
													<li>
														<a href={AccueilCaf && AccueilCaf.url} onClick={this.toggleMenuMobile}>
															{this.props.t("LabelAccueilCAF")}
														</a>
													</li>
													{MainNavCaf.children.map((MainNavCafItem, index) => (
														<li key={index}>
															{MainNavCafItem.hasChildren && (
																<button
																	className="bt_mobile-nav"
																	type="button"
																	data-id={MainNavCafItem.id}
																	data-label={MainNavCafItem.destination.text}
																	onClick={(e) => this.handleNextNavMobile(e, 2, 0)}
																>
																	{MainNavCafItem.destination.text}
																</button>
															)}
															{!MainNavCafItem.hasChildren && <Link field={MainNavCafItem.destination.jss} onClick={this.toggleMenuMobile}></Link>}
														</li>
													))}
													<li className="li_compte">
														<button
															className="bt_mobile-nav"
															type="button"
															data-id="compte"
															data-label={this.props.t("MenuCompte")}
															onClick={(e) => this.handleNextNavMobile(e, 2, 0)}
														>
															<IconSVG icon="avatar" width="17" height="22" />
															{this.props.t("MenuCompte")}
														</button>
													</li>
													<li className="li_contact">
														{PageContact && (
															<a href={PageContact.url}>
																<IconSVG icon="email" width="20" height="16" />
																{this.props.t("MenuContact")}
															</a>
														)}
													</li>
												</ul>
												<ul className="second_nav-mobile">
													{SecondNavMobile.children.map((SecondNavMobileItem, index) => (
														<li key={index}>
															<Link field={SecondNavMobileItem.destination.jss} onClick={this.toggleMenuMobile}></Link>
														</li>
													))}
												</ul>
											</>
										)}
									</div>

									<div className="ctn_panel level-2">
										{this.state.dataId === "marque" ? (
											<ul className="main_nav-mobile level-marque">
												{MainNavCaf.children[MainNavCaf.children.findIndex((item) => item.cSSClass.value === "marque")].SubMenu.map((SubMenuItem, index) => (
													<li key={index}>
														<button
															className="bt_mobile-nav"
															type="button"
															data-id={SubMenuItem.cSSClass.value}
															data-label={SubMenuItem.destination.text}
															onClick={(e) => this.handleNextNavMobile(e, 3, index)}
														>
															{SubMenuItem.destination.text}
														</button>
													</li>
												))}
											</ul>
										) : this.state.dataId === "compte" ? (
											<ul className="main_nav-mobile level-compte">
												{navCompte.children.map((navCompteItem, index) => (
													<li key={index}>
														<Link field={navCompteItem.destination.jss} onClick={this.toggleMenuMobile}></Link>
													</li>
												))}
												{/*eslint-disable */}
												<li>
													<a onClick={() => this.handleLogOut()}>{this.props.t("MenuDeconnexion")}</a>
												</li>
												{/*eslint-enable */}
											</ul>
										) : (
											MainNavCaf.children.find((o) => o.id === this.state.dataId) && (
												<ul className="main_nav-mobile level-marque">
													{MainNavCaf.children
														.find((o) => o.id === this.state.dataId)
														.SubMenu.map(
															(SubMenuItem, index) =>
																SubMenuItem.destination.url !== "" && (
																	<li key={index}>
																		<Link field={SubMenuItem.destination.jss} onClick={this.toggleMenuMobile}></Link>
																	</li>
																)
														)}
												</ul>
											)
										)}
									</div>

									<div className="ctn_panel level-3">
										{this.state.dataLevel === 3 && (
											<ul
												className={
													"main_nav-mobile level-" + MainNavCaf.children[0].SubMenu[this.state.marqueIndex < 0 ? iCurrentIndexMenu : this.state.marqueIndex].cSSClass.value
												}
											>
												<li>
													<Link
														field={MainNavCaf.children[0].SubMenu[this.state.marqueIndex < 0 ? iCurrentIndexMenu : this.state.marqueIndex].destination.jss}
														onClick={this.toggleMenuMobile}
													>
														{this.props.t("LabelAccueilCAF")}
													</Link>
												</li>
												{MainNavCaf.children[0].SubMenu[this.state.marqueIndex < 0 ? iCurrentIndexMenu : this.state.marqueIndex].SubMenu.map(
													(SubMenuItem, index) =>
														SubMenuItem.destination.url !== "" && (
															<li key={index}>
																<Link field={SubMenuItem.destination.jss} onClick={this.toggleMenuMobile}></Link>
															</li>
														)
												)}
											</ul>
										)}
									</div>
								</div>
							</div>
						)}
					</header>
				)}

				{this.state.apiDatasUserBadgesNotDisplayed.UserBadges?.map((userBadge, index) => {
					const badge = this.state.apiDatasUserBadgesNotDisplayed.Badges.find((badge) => badge.Badge_Id === userBadge.UserBadge_Badge_Id);
					const badgeDataTheme = badges.find((item) => item.type === badge?.Badge_Type)?.theme.find((item) => item.theme === badge?.Badge_Theme);

					return (
						<ExpertiseBadgeModal
							key={index}
							onRef={(ref) => (this["ExpertiseBadgeModal" + index] = ref)}
							badge={badge}
							userBadge={userBadge}
							badgeDataTheme={badgeDataTheme}
							newBadge={this.state.newBadge}
							bodyOpenClassName="ReactModal__Body--open_blur"
							handleClose={this.handleOpenModalBadge}
						/>
					);
				})}
			</>
		);
	}
}

export default withTranslation()(withSitecoreContext()(MainHeader));
