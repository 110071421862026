import React, { Component, useState } from "react";

import { formatDate, isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { getApiDatas, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

class PageChallengeDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: isMobile(),
			apiDatas: [],
		};
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Challenge/GetDataPageBeneficiaires`).then((apiDatas) => {
			this.setState({ apiDatas: apiDatas });
		});

		document.body.classList.add("pg_challenge-detail");
		window.scrollTo(0, 0);
	}
	componentWillUnmount() {
		document.body.classList.remove("pg_challenge-detail");
	}
	toggleManager = (e) => {
		document.body.classList.add("is-loading");
		postApiDatas("/apiaox/Challenge/PostChallengeManager", {
			UserId: e.currentTarget.id.slice(9),
			Manager: e.currentTarget.checked,
		}).then((res) => {
			document.body.classList.remove("is-loading");
			if (!res.IsValid) {
				alert(res.Message);
			}
		});
	};
	toggleDeleted = (e) => {
		document.body.classList.add("is-loading");
		postApiDatas("/apiaox/Challenge/PostUserEnable", {
			UserId: e.currentTarget.id.slice(17),
			Enable: e.currentTarget.checked,
		}).then((res) => {
			document.body.classList.remove("is-loading");
			if (!res.IsValid) {
				alert(res.Message);
			}
		});
	};
	render() {
		const apiDatas = this.state.apiDatas;
        const ROLE_TITULAIRE = "TITULS"; 
        // TODO SGU gestions des constantes

		const TableHeader = ({ headers, handleSort, sortedField, sortDirection }) => {
			return (
				<div className="obj_table-header">
					{headers.map((header, index) => (
						<div
							key={index}
							className={`header-cell ${header.sortable ? "sortable" : ""}${header.field === sortedField && sortDirection === 1 ? " active" : ""}`}
							onClick={() => handleSort(header.field)}
						>
							<span dangerouslySetInnerHTML={{ __html: header.name }} />
						</div>
					))}
				</div>
			);
		};

		const TableRow = ({ rowData, handleExpand }) => {
			const [isExpanded, setIsExpanded] = useState(false);

			const handleButtonClick = () => {
				setIsExpanded(!isExpanded);
			};

			return (
				<React.Fragment>
					<div className={`table-row ${isExpanded ? "active" : ""}`} onClick={() => handleExpand(rowData.id)}>
						<div className="row-cell">
							{rowData["Repartitions" + new Date().getFullYear()].length > 0 && (
								<button className="obj_btn_expend-cell" onClick={handleButtonClick}>
									Expand
								</button>
							)}
						</div>
						<div className="row-cell">{rowData.Name}</div>
						<div className="row-cell">{rowData.Mail}</div>
						<div className="row-cell">{rowData.RoleName}</div>
						<div className="row-cell">
                            {   
                                rowData.RoleCode === ROLE_TITULAIRE
                                ? <input className="tgl tgl-light" id={`checkbox_${rowData.Id}`} type="checkbox" checked={rowData.ChallengeManager} disabled={true} />
                                : <input className="tgl tgl-light" id={`checkbox_${rowData.Id}`} type="checkbox" defaultChecked={rowData.ChallengeManager} onChange={(e) => this.toggleManager(e)} />
                            }
							<label className="tgl-btn" htmlFor={`checkbox_${rowData.Id}`}></label>
						</div>
						<div className="row-cell">
							<strong>
								{rowData.GainYearTotal}
								<sup>€</sup>
							</strong>
						</div>
                        <div className="row-cell">
                            {   
                                rowData.RoleCode === ROLE_TITULAIRE
                                ? <input className="tgl tgl-light" id={`checkbox_deleted_${rowData.Id}`} type="checkbox" checked={!rowData.IsDeleted} disabled={true} />
                                : <input className="tgl tgl-light" id={`checkbox_deleted_${rowData.Id}`} type="checkbox" defaultChecked={!rowData.IsDeleted} onChange={(e) => this.toggleDeleted(e)} />
                            }
                            <label className="tgl-btn" htmlFor={`checkbox_deleted_${rowData.Id}`}></label>
                        </div>
					</div>
					{rowData["Repartitions" + new Date().getFullYear()].length > 0 && isExpanded && (
						<div className="ctn_expand">
							{rowData["Repartitions" + new Date().getFullYear()].map((formItem, index) => (
								<div key={index} className="ctn_expand-row">
									<div className="expand_date">{formatDate(formItem.Repartition_GainDate)}</div>
									<div className="expand_marque">{formItem.Brand_Name}</div>
									<div className="expand_libelle">{formItem.Challenge_Title}</div>
									<div className="expand_montant">
										<strong>
											{formItem.Repartition_ValueRaw}
											<sup>€</sup>
										</strong>
									</div>
								</div>
							))}
						</div>
					)}
				</React.Fragment>
			);
		};

		const Table = ({ tableData, headers }) => {
			const [sortedField, setSortedField] = useState("");
			const [sortDirection, setSortDirection] = useState(1);

			const handleSort = (field) => {
				setSortedField(field);
				setSortDirection(sortDirection === 1 ? -1 : 1);
			};

			const handleExpand = (id) => {
				// Implement your expand logic here...
			};

			const sortedTableData = tableData.sort((a, b) => {
				if (!sortedField) return 0;
				if (a[sortedField] > b[sortedField]) {
					return sortDirection;
				} else if (a[sortedField] < b[sortedField]) {
					return -sortDirection;
				}
				return 0;
			});

			return (
				<div className="obj_tableau-beneficiaire">
					<TableHeader headers={headers} handleSort={handleSort} sortedField={sortedField} sortDirection={sortDirection} />
					{sortedTableData.map((rowData) => (
						<TableRow key={rowData.id} rowData={rowData} handleExpand={handleExpand} />
					))}
				</div>
			);
		};

		let tableHeaders = [
			{ name: "", field: "id", sortable: false },
			{ name: "Prénom/Nom", field: "Name", sortable: true },
			{ name: "E-Mail", field: "Mail", sortable: true },
			{ name: "Rôle", field: "RoleName", sortable: true },
			{ name: "Responsable challenge", field: "ResponsabilityCode", sortable: false },
			{ name: "Montant<br/>acquis", field: "GainYearTotal", sortable: true },
			{ name: "Membre Actif", field: "IsDeleted", sortable: false }
		];

		return (
			<>
				<section className="obj_section section_challenges">
					<div className="ctn_section">
						<div className="ctn_title">
							<h1 className="t_title">Mes bénéficiaires</h1>
						</div>

						{(!apiDatas.User?.ChallengeManager || !apiDatas.Membres) ? (
							<></>
						) : !this.state.isMobile ? (
							<Table tableData={apiDatas.Membres} headers={tableHeaders} />
						) : (
							<div class="obj_tableau-beneficiaire">
								{apiDatas.Membres.map((membre, index) => (
									<div class={`table-row${this.state.activeMenuGain ? " is-expanded" : ""}`} key={index}>
										<div class="table-header">
											<div class="row-cell">{membre.Name}</div>
										</div>
										<div class="table-ctn-row">
											<div class="header-cell">E-mail</div>
											<div class="row-cell">{membre.Mail}</div>
										</div>
										<div class="table-ctn-row">
											<div class="header-cell">Rôle</div>
											<div class="row-cell">{membre.RoleName}</div>
										</div>
										<div class="table-ctn-row">
											<div class="header-cell">Responsable challenge</div>
											<div class="row-cell">
                                                {   
                                                    membre.RoleCode === ROLE_TITULAIRE
                                                    ? <input class="tgl tgl-light" id={`checkbox_${membre.Id}`} type="checkbox" checked={membre.ChallengeManager} disabled={true} />
                                                    : <input class="tgl tgl-light" id={`checkbox_${membre.Id}`} type="checkbox" defaultChecked={membre.ChallengeManager} onChange={(e) => this.toggleManager(e)} />
                                                }
												<label class="tgl-btn" for={`checkbox_${membre.Id}`}></label>
											</div>
										</div>
										<div class="table-ctn-row">
											<div class="header-cell">Montant acquis</div>
											<div class="row-cell">
												<strong>
													{membre.GainYearTotal}
													<sup>€</sup>
												</strong>
											</div>
										</div>
                                        <div class="table-ctn-row">
                                            <div class="header-cell">Actif</div>
                                            <div class="row-cell">
                                                {   
                                                    membre.RoleCode === ROLE_TITULAIRE
                                                    ? <input class="tgl tgl-light" id={`checkbox_deleted_${membre.Id}`} type="checkbox" checked={membre.IsDeleted} disabled={true} />
                                                    : <input class="tgl tgl-light" id={`checkbox_deleted_${membre.Id}`} type="checkbox" defaultChecked={membre.IsDeleted} onChange={(e) => this.toggleDeleted(e)} />
                                                }
                                                <label class="tgl-btn" for={`checkbox_deleted_${membre.Id}`}></label>
                                            </div>
                                        </div>

                                        {membre["Repartitions" + new Date().getFullYear()].length > 0 && (
                                            <>
                                                <button
                                                    className={`obj_btn_expand-detail${this.state.activeMenuGain ? " is-expanded" : ""}`}
                                                    onClick={() => this.setState({ activeMenuGain: !this.state.activeMenuGain })}
                                                >
                                                    <span>voir le détail</span>
                                                </button>

                                                <div className="obj_table-gains-content">
                                                    <div className="ctn_expand">
                                                        {membre["Repartitions" + new Date().getFullYear()].map((formItem, index) => (
                                                            <div key={index} className="ctn_expand-row">
                                                                <div className="expand_date">{formatDate(formItem.Repartition_GainDate)}</div>
                                                                <div className="expand_marque">{formItem.Brand_Name}</div>
                                                                <div className="expand_libelle">{formItem.Challenge_Title}</div>
                                                                <div className="expand_montant">
                                                                    <strong>
                                                                        {formItem.Repartition_ValueRaw}
                                                                        <sup>€</sup>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}
									</div>
								))}
							</div>
						)}
					</div>
				</section>
			</>
		);
	}
}

export default PageChallengeDetail;
