import React, { Component } from 'react'
import Modal from 'react-modal' 
import { withTranslation } from 'react-i18next'; 
import { getApiDatas, postApiDatas } from '../../utilities/FrontFunctions/AoxFrontFunctions';

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class SatisfactionFormModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalIsOpen: false,
			apiDatas:null
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeNoMoreModal(formCode) {
		localStorage.setItem(formCode, 'true')
		this.setState({modalIsOpen: false})
		
	}
	closeModal() {	
		this.setState({modalIsOpen: false})
	}
	
	
	
	
	render() {
		console.log(this.props.SatisfactionForm)
		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_main">{"Vous avez la possibilité de gagner 1000 pts en nous partageant votre avis sur le site."}</p>
						<p className="t_main">{"Souhaitez-vous remplir le questionnaire?"}</p>
						<p className="ctn_cta">
							{this.props?.SatisfactionForm?.Url &&
								<a className='obj_cta' 
									href={this.props.SatisfactionForm.Url}
								>
									{"OUI"}
								</a>
							}
							<button className="obj_cta" onClick={()=>this.closeNoMoreModal(this.props.SatisfactionForm.Code)}>
							{"NON"}
							</button>
							
						</p>
						
							
						
					</div>
				</Modal>
			</div>
		)
	}
}
export default  withTranslation()(SatisfactionFormModal)