import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import { Formik } from 'formik'
import * as Yup from 'yup'

import { throttle, isMobile} from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import { postApiDatas, dayRemaining, strDateToFrLong, urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { dataEventTypes } from "../../data/events"

import CustomSelect from '../../utilities/FrontFunctions/FormElements/CustomSelect'
import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import DefaultModal from '../Modal/DefaultModal'

class ObjEventDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isMobile: isMobile(),
			activeSession : this.props.event?.Sessions?.find(o => this.props.eventSessionId == o.EventSession_Id) 
                            || this.props.event?.Sessions?.find(o => o.UserRegistered)
                            || this.props.event.Sessions.at(0)
		}
		this.handleWindowResizeMainHeader = this.handleWindowResizeMainHeader.bind(this)
		this.throttleWindowResizeMainHeader = throttle(this.handleWindowResizeMainHeader, 200)
	}
	handleWindowResizeMainHeader() {
		this.setState({
			isMobile: isMobile()
		})
	}
    setActiveSession(id){
        this.setState({
            activeSession : this.props.event.Sessions.find(o => o.EventSession_Id === id)
        })
    }
    registration(values){
        if(!this.state.onRegistration){
            this.setState({ onRegistration : true });
            document.body.classList.add('is-loading')
            postApiDatas(`/apiaox/Event/PostEventRegistration`, values)
            .then((res) => {
                document.body.classList.remove('is-loading')
                this.setState({
                    formSubmitted: res.IsValid,
                    apiError: !res.IsValid,
                    apiErrorMsg: res.Message,
                    onRegistration: false,
                })
                if(res.IsValid){
                    this.ModalRegistrationDone.openModal();
                }
            });
        }
    }
    
	render() {
       
        const Event = this.props.event;
		const ActiveSession = this.state.activeSession;
		const DayRemaining = ActiveSession ? dayRemaining(ActiveSession.EventSession_StartDate) : -1;
		return (<>
            { Event && ActiveSession &&
                <div className={`obj_formation_detail
                    ${' ' + dataEventTypes[Event.EventType_Code].css}
                    ${' ' + Event.Brand.Brand_CssClass}
                    ${ActiveSession.UserRegistered ? ' is_registered' : '' }`
                }>
                    <div className="ctn_row">
                        <div className="ctn_image">
                            <div className="ctn_visuel">
                                <img src={urlToHost(Event.Event_MediaUrl)} alt={Event.Event_MediaAlt}/>
                            </div>

                            <div className="ctn_logo">
                                <img src={urlToHost(Event.Brand.Brand_LogoUrl)} alt={Event.Brand.Brand_Name}/>
                            </div>
                        </div>

                        <div className="ctn_text">	
                            <p className={`t_tag`}>
                                {Event.EventType_Name}
                            </p>

                            {ActiveSession.UserRegistered === true && (
                                (DayRemaining < 0) 
                                ? <></>
                                :	(DayRemaining===0)
                                    ? <div className="obj_jours-restant">Dernier jour</div>
                                    : <div className="obj_jours-restant">J-{DayRemaining}</div>
                            )}

                            <ul className="list_infos">
                                {ActiveSession.EventSession_EndDate != null
                                    ?<li className="t_date">
                                        Du {strDateToFrLong(ActiveSession.EventSession_StartDate)} Au {strDateToFrLong(ActiveSession.EventSession_EndDate)}
                                    </li>
                                    :<li className="t_date">
                                        {strDateToFrLong(ActiveSession.EventSession_StartDate)}
                                    </li>
                                }
                                <li className="t_info">
                                    {Event.EventType_Code === 'WEB' 
                                        ? ActiveSession.EventSession_StartHour ? ActiveSession.EventSession_StartHour : "-"
                                        : ActiveSession.Place?.EventPlace_City ? ActiveSession.Place.EventPlace_City : "-"
                                    }
                                </li>
                            </ul>

                            {(ActiveSession.UserRegistered === true ) &&
                                <p className="t_inscription">vous êtes inscrits</p>
                            }

                            <p className="t_title">{Event.Event_SubTitle}</p>
                            <p className="t_desc" dangerouslySetInnerHTML={{__html: Event.Event_Description}}/>

                            {ActiveSession?.UserRegistered === true 
                                ? (DayRemaining > -1) && <>
                                    <div className="ctn_cta">
                                        {Event.EventType_Code !== 'WEB' &&
                                            <a className="obj_btn picto" href={ActiveSession.UrlConvocation} target="_blank" rel="noopener noreferrer">
                                                <IconSVG icon="telecharger" width="23.595" height="20.227" />
                                                télécharger votre convocation
                                            </a>
                                        }
                                        <button className="obj_btn light" 
                                            onClick={() => {
                                                this.registration({
                                                    EventSession_Id:ActiveSession.EventSession_Id, 
                                                    Registration : false,
                                                });
                                            }}
                                        >
                                            se désinscrire
                                        </button>
                                        <DefaultModal
                                            onRef={ref => (this.ModalRegistrationDone = ref)}
                                            onClose={() => window.location.reload(false)}
                                            htmlContent={
                                                <>
                                                    <p className="t_title">Votre Désinscription est enregistrée.</p>
                                                    <p className="t_main"></p>
                                                    <div className="ctn_cta">
                                                        <button className="obj_cta" type="button" onClick={() => this.ModalRegistrationDone.closeModal()}>fermer</button>
                                                    </div>
                                                </>
                                            }
                                        />
                                    </div>
                                    {ActiveSession.Place?.EventPlace_MediaUrl &&
                                        <div className="ctn_visuel_map">
                                            <img src={urlToHost(ActiveSession.Place.EventPlace_MediaUrl)} alt={ActiveSession.Place.EventPlace_MediaAlt}/>
                                        </div>
                                    }
                                </>
                                : <>
                                    <Formik
                                        initialValues={{
                                            EventSession_Id: ActiveSession.EventSession_Id,
                                            Registration : true,
                                        }}
                                        validationSchema={
                                            Yup.object().shape({
                                                EventSession_Id: Yup.string().required()
                                            })
                                        }
                                        onSubmit={(values) => {
                                            this.registration(values)
                                        }}
                                    >
                                        { ({ handleSubmit, setFieldValue, setFieldTouched, errors, touched, values }) => (
                                            <div className="obj_formation_detail_form">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="obj_form">
                                                        <label className="t_label">Autres dates disponibles :</label>

                                                        <CustomSelect
                                                            fieldName={'EventSession_Id'}
                                                            placeholder={strDateToFrLong(ActiveSession.EventSession_StartDate)
                                                                        + (Event.EventType_Code === 'WEB' 
                                                                            ? ActiveSession.EventSession_StartHour ? " à " + ActiveSession.EventSession_StartHour : ""
                                                                            : ActiveSession.Place?.EventPlace_City ? " à " + ActiveSession.Place.EventPlace_City : ""
                                                                        )}
                                                            options={
                                                                Event.Sessions.map((session) => ({ 
                                                                    value: session.EventSession_Id, 
                                                                    label: (strDateToFrLong(session.EventSession_StartDate)
                                                                            + (Event.EventType_Code === 'WEB' 
                                                                                ? session.EventSession_StartHour ? " à " + session.EventSession_StartHour : ""
                                                                                : session.Place?.EventPlace_City ? " à " + session.Place.EventPlace_City : ""
                                                                            ))
                                                                }))
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            onChange={option => {this.setActiveSession(option.value);}}
                                                            errors={errors}
                                                            touched={touched}
                                                            values={values}
                                                        />
                                            
                                                        <div className="ctn_cta">
                                                            <button className="obj_cta" type="submit">je m'inscris</button>
                                                        </div>

                                                        {( (touched.EventSession_Id && errors.EventSession_Id) ) && 
                                                            <p className="t_error mb_30 fade-in">Il y a des erreurs dans le formulaire.</p>
                                                        }

                                                        {this.state.apiError && 
                                                            <p className="t_error mb_30 fade-in"><br/>{this.state.apiErrorMsg}</p>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </Formik>
                                    <DefaultModal
                                        onRef={ref => (this.ModalRegistrationDone = ref)}
                                        onClose={() => window.location.reload(false)}
                                        htmlContent={
                                            <>
                                                <p className="t_title">Votre Inscription est enregistrée.</p>
                                                <p className="t_main"></p>
                                                <div className="ctn_cta">
                                                    <button className="obj_cta" type="button" onClick={() => this.ModalRegistrationDone.closeModal()}>fermer</button>
                                                </div>
                                            </>
                                        }
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>)
	}
}

export default withTranslation()(ObjEventDetail)