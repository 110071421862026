import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { throttle, isMobile, isNotAtTop } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import { getApiDatas, isDateValid } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjPushEventSession from '../ObjEvent/ObjPushEventSession';

class PageEvents extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isMobile: isMobile(),
			pushsToShow: 5,
			pushsInterval: 3,
			apiDatas:[],
			activeBrands:[],
			activeTypes:[],
			
			activeStartDate:null,
			activeEndDate:null,
			eventsFiltered:[],
			citiesFiltered:[],
		}

		this.handleWindowResizeMainHeader = this.handleWindowResizeMainHeader.bind(this)
		this.throttleWindowResizeMainHeader = throttle(this.handleWindowResizeMainHeader, 200)
    	this.handleWindowScrollMainHeader = this.handleWindowScrollMainHeader.bind(this)
		this.throttleWindowScrollMainHeader = throttle(this.handleWindowScrollMainHeader, 200)

		this.InputEventStartDate = React.createRef();
		this.InputEventEndDate = React.createRef();
	}


	componentDidMount() {
		

		getApiDatas(`/apiaox/Event/GetDataPageEvents`)
		.then((activeApiDatas) => { 
			
			this.setState({ apiDatas:{...activeApiDatas, Cities: this.getUniqueCitiesWithData(activeApiDatas)} }) 
			this.filterReset();
		})

		document.body.classList.add('pg_formation')
		window.addEventListener('resize', this.throttleWindowResizeMainHeader)
		window.addEventListener('scroll', this.throttleWindowScrollMainHeader)
		window.scrollTo(0,0)
  	}
  	componentWillUnmount() {
		document.body.classList.remove('pg_formation')
		window.removeEventListener('resize', this.throttleWindowResizeMainHeader)
		window.removeEventListener('scroll', this.throttleWindowScrollMainHeader)
	}
	handleWindowResizeMainHeader() {
		this.setState({
			isMobile: isMobile()
		})
	}
	handleWindowScrollMainHeader() {
		this.setState({
			modeMini: isNotAtTop()
		})
	}
	showMorePushs = () => {
		this.setState(prevState => ({
			pushsToShow: prevState.pushsToShow + this.state.pushsInterval
		}))
	}
	filterBrand = (brandCode) => {
		let newActiveBrands = this.state.activeBrands;
		const index = this.state.activeBrands.indexOf(brandCode);
		(index === -1) ? newActiveBrands.push(brandCode) : newActiveBrands.splice(index, 1);

		this.setState({
			activeBrands: newActiveBrands,
		}, function() {
			this.filterFormations();
		})
	}
	filterType = (typeCode) => {
		let newActiveTypes = this.state.activeTypes;
		const index = this.state.activeTypes.indexOf(typeCode);
		(index === -1) ? newActiveTypes.push(typeCode) : newActiveTypes.splice(index, 1);

		this.setState({
			activeTypes: newActiveTypes,
		}, function() {
			this.filterFormations();
		})
	}
	filterCity = (selectedCity) => {
		let existing = this.state.citiesFiltered.find(city => city === selectedCity)

		this.setState({
			citiesFiltered: existing ? this.state.citiesFiltered.filter(city =>city != selectedCity):[...this.state.citiesFiltered,selectedCity],
		}, function() {
			this.filterFormations();
		})
	}
	filterDateBegin = (dateStart) => {
		if(isDateValid(dateStart)){
			this.setState({
				activeStartDate: new Date(dateStart + 'T00:00:00'),
			}, function() {
				this.filterFormations();
			})
		}
	}
	filterDateEnd = (dateEnd) => {
		if(isDateValid(dateEnd)){
            var dDateEnd = new Date(dateEnd + 'T00:00:00');
            dDateEnd.setDate(dDateEnd.getDate() + 1);

			this.setState({
				activeEndDate: dDateEnd,
			}, function() {
				this.filterFormations();
			})
		}
	}
	filterReset = () => {
		this.setState({
			activeBrands: [],
			activeTypes: [],
			citiesFiltered:[],
			activeStartDate: null,
			activeEndDate: null,
			
		}, function() {
			this.InputEventStartDate.current.value = "";
			this.InputEventEndDate.current.value = "";
			this.filterFormations();
		})
	}
	filterFormations(){
		this.setState({
			pushsToShow:5,
			eventsFiltered:this.state.apiDatas.Events?.filter((event) => {return (this.state.activeBrands.length === 0)
																				|| this.state.activeBrands.includes(event.Brand.Brand_Code);})
													.filter((event) => {return (this.state.activeTypes.length === 0)
																				|| this.state.activeTypes.includes(event.EventType_Code);})
													.map((event) => {return !this.state.activeStartDate ? 
														event
														: 
														{...event, Sessions:event.Sessions.filter(o => new Date(o.EventSession_StartDate) >= this.state.activeStartDate)}
													})
													.map((event) => {return !this.state.activeEndDate ? 
														event
														: 
														{...event, Sessions:event.Sessions.filter(o => new Date(o.EventSession_StartDate) < this.state.activeEndDate)}
													})
													.map((event) => { return this.state.citiesFiltered.length === 0 ? 
														event
														: 
														{...event, Sessions:event.Sessions.filter(o => this.state.citiesFiltered.includes(o?.Place?.EventPlace_City))}
													})

		})
	}

	getUniqueCitiesWithData(data) {
		const citiesSet = new Set();
	  
		data.Events.map(event => {
		  event.Sessions.map(session => {
			const cityName = session?.Place?.EventPlace_City;
			if (cityName) {
				citiesSet.add(cityName);
			}
			return null
		  });
		  return null
		});
		const uniqueCitiesWithData = Array.from(citiesSet).sort()
		return uniqueCitiesWithData;
	  }

	render() {
		const apiDatas = this.state.apiDatas
		const { PageEventDetail } = this.props.fields.data;


		return (<>
			<section className="obj_section section_formation">
				<div className="ctn_section">
					<div className="ctn_title">
						<h1 className="t_title">Prochaines formations</h1>
					</div>
				
					<div className="ctn_row">
						<section className="ctn_filter">
							{ 	!this.state.isMobile 
								?<div className="obj_filter-challenge">
									<div className="obj_filters_header">
										<h3 className="t_title">Filtre(s)</h3>
										<div className="obj_remove-filter">
											<button className="obj_header_exp" onClick={() => this.filterReset()}>Tout réinitialiser</button>
										</div>
									</div>

									{apiDatas.Brands && 
										<div className="obj_filter-list-item">
											<div className="t_title">Par marque</div>
											<ul className="obj_filter-list-container">
												{apiDatas.Brands.map((brand, index) => (
													<li key={index}>
														<div className="ctn_form-element">
															<input
																type="checkbox"
																id={brand.Brand_Code}
																name={brand.Brand_Code}
																value={brand.Brand_Code}
																onChange={() => this.filterBrand(brand.Brand_Code)}
																checked={this.state.activeBrands.indexOf(brand.Brand_Code) > -1}
															/>
															<label htmlFor={brand.Brand_Code}>
																{brand.Brand_Name}
															</label>
														</div>
													</li>
												))}
											</ul>
										</div>
									}

									{apiDatas.EventTypes && 
										<div className="obj_filter-list-item">
											<div className="t_title">Par type</div>
											<ul className="obj_filter-list-container">
												{apiDatas.EventTypes.map((eventType, index) => (
													<li key={index}>
														<div className="ctn_form-element">
															<input
																type="checkbox"
																id={eventType.EventType_Code}
																name={eventType.EventType_Code}
																value={eventType.EventType_Code}
																onChange={() => this.filterType(eventType.EventType_Code)}
																checked={this.state.activeTypes.indexOf(eventType.EventType_Code) > -1}
															/>
															<label htmlFor={eventType.EventType_Code}>
																{eventType.EventType_Name}
															</label>
														</div>
													</li>
												))}
											</ul>
										</div>
									}

									<div className="obj_filter-list-item var_date">
										<div className="t_title">Par date</div>
										<ul className="obj_filter-list-container">
											<li>
												<div className="ctn_form-element">
													<span>Du</span>
													<input 
														type="date" 
														id="EventStartDate" 
														name="date_start" 
														ref={this.InputEventStartDate}
														placeholder="jj/mm/aaaa" 
														maxLength={10} 
														onChange={(e) => this.filterDateBegin(e.target.value)}
													/>
												</div>
											</li>
											<li>
												<div className="ctn_form-element">
													<span>Au</span>
													<input 
														type="date" 
														id="EventEndDate"
														name="date_end" 
														ref={this.InputEventEndDate}
														placeholder="jj/mm/aaaa" 
														maxLength={10} 
														onChange={(e) => this.filterDateEnd(e.target.value)}
													/>
												</div>
											</li>
										</ul>
									</div>
									{apiDatas?.Cities?.length>0 && 
										<div className="obj_filter-list-item">
											<div className="t_title">Par ville</div>
											<ul className="obj_filter-list-container">
												{apiDatas.Cities.map((city, index) => (
													<li key={index}>
														<div className="ctn_form-element">
															<input
																type="checkbox"
																id={city}
																name={city}
																value={city}
																onChange={(e) => this.filterCity(city)}
																checked={this.state.citiesFiltered?.find(activeCity=>activeCity === city)}
															/>
															<label htmlFor={city}>
																{city}
															</label>
														</div>
													</li>
												))}
											</ul>
										</div>
									}
								</div>
								:<>
									<div className={`obj_filter-challenge${this.state.active ? ' is-expanded' : ''}`}>
										<div className="obj_filters_header">
											<h3 className="t_title">Filtrer</h3>
											<div className="obj_remove-filter">
												<button className="obj_btn_reinit_filters" onClick={() => this.filterReset()}>Tout réinitialiser</button>
											</div>
										</div>

										<div className="ctn_row">
											{apiDatas.Brands && 
												<div className="obj_filter-list-item">
													<div className="t_title">Par marque</div>
													<ul className="obj_filter-list-container">
														{apiDatas.Brands.map((brand, index) => (
															<li key={index}>
																<div className="ctn_form-element">
																	<input
																		type="checkbox"
																		id={brand.Brand_Code}
																		name={brand.Brand_Code}
																		value={brand.Brand_Code}
																		onClick={() => this.filterBrand(brand.Brand_Code)}
																		checked={this.state.activeBrands.indexOf(brand.Brand_Code) > -1}
																	/>
																	<label htmlFor={brand.Brand_Code}>
																		{brand.Brand_Name}
																	</label>
																</div>
															</li>
														))}
													</ul>
												</div>
											}

											{apiDatas.EventTypes && 
												<div className="obj_filter-list-item">
													<div className="t_title">Par type</div>
													<ul className="obj_filter-list-container">
														{apiDatas.EventTypes.map((eventType, index) => (
															<li key={index}>
																<div className="ctn_form-element">
																	<input
																		type="checkbox"
																		id={eventType.EventType_Code}
																		name={eventType.EventType_Code}
																		value={eventType.EventType_Code}
																		onChange={() => this.filterType(eventType.EventType_Code)}
																		checked={this.state.activeTypes.indexOf(eventType.EventType_Code) > -1}
																	/>
																	<label htmlFor={eventType.EventType_Code}>
																		{eventType.EventType_Name}
																	</label>
																</div>
															</li>
														))}
													</ul>
												</div>
											}

											<div className="obj_filter-list-item var_date">
												<div className="t_title">Par date</div>
												<ul className="obj_filter-list-container">
													<li>
														<div className="ctn_form-element">
															<span>Du</span>
															<input 
																type="date" 
																id="EventStartDate" 
																name="date_start" 
																ref={this.InputEventStartDate}
																placeholder="jj/mm/aaaa" 
																maxLength={10} 
																onChange={(e) => this.filterDateBegin(e.target.value)}
															/>
														</div>
													</li>
													<li>
														<div className="ctn_form-element">
															<span>Au</span>
															<input 
																type="date" 
																id="EventEndDate"
																name="date_end" 
																ref={this.InputEventEndDate}
																placeholder="jj/mm/aaaa" 
																maxLength={10} 
																onChange={(e) => this.filterDateEnd(e.target.value)}
															/>
														</div>
													</li>
												</ul>
											</div>
											{apiDatas?.Cities?.length>0 && 
												<div className="obj_filter-list-item">
													<div className="t_title">Par ville</div>
													<ul className="obj_filter-list-container">
														{apiDatas.Cities.map((city, index) => (
															<li key={index}>
																<div className="ctn_form-element">
																	<input
																		type="checkbox"
																		id={city}
																		name={city}
																		value={city}
																		onChange={(e) => this.filterCity(city)}
																		checked={this.state.citiesFiltered?.find(activeCity=>activeCity === city)}
																	/>
																	<label htmlFor={city}>
																		{city}
																	</label>
																</div>
															</li>
														))}
													</ul>
												</div>
											}
										</div>
									</div>

									<div className={`obj_filter-challenge-menu${this.state.active ? ' is-clicked' : ''}`}>
										<button className="obj_btn_filter-challenge" onClick={ () => this.setState({active: !this.state.active}) }>
											{/* <!-- is-expanded --> */}
										</button>
									</div>
								</>
							}
						</section>

						<section className="ctn_list_formation">
							{ 	!apiDatas.Events 
								//API non chargée
								?<div className="obj_challenge is_empty"/>
								:	apiDatas.Events.length<1
									//pas de participations par l'API
									?<div className="obj_formation is_empty">
										<div className="ctn_text">
											<p className="t_title" dangerouslySetInnerHTML={{__html: "Votre équipe L'Oréal<br /> Beauté Dermatologique<br /> prépare avec soin vos<br /> prochains événements<br /><br />A très vite !"}} />
										</div>
										<picture className="ctn_background">
											<source srcSet={require('../../assets/images/v_empty_list_mob.jpg')} media="(max-width:767px)"/>
											<img src={require('../../assets/images/v_empty_list.jpg')} alt=""/>
										</picture>
									</div>
									:	this.state.eventsFiltered.length < 1
										//pas de participations par le filtrage
										?<div className="obj_formation is_empty">
											<div className="ctn_text">
												<p className="t_title" dangerouslySetInnerHTML={{__html: "Aucun résultat"}} />
											</div>
											<picture className="ctn_background">
												<source srcSet={require('../../assets/images/v_empty_list_mob.jpg')} media="(max-width:767px)"/>
												<img src={require('../../assets/images/v_empty_list.jpg')} alt=""/>
											</picture>
										</div>
										//liste filtrée
										: <>
											{this.state?.eventsFiltered?.flatMap(event=> 
												event?.Sessions?.map((eventSession,index)=>
													{return({
														...eventSession,
														EventType_Code:event.EventType_Code,
														EventType_Name:event.EventType_Name,
														Event_Title:event.Event_Title,
														Brand:event.Brand,Event_Id:event.Event_Id,
														Event_MediaUrl:event.Event_MediaUrl,
														Event_MediaAlt:event.Event_MediaAlt
													})}))
														.sort((e1,e2) =>  new Date(e1.EventSession_StartDate)-new Date(e2.EventSession_StartDate))
														.slice(0, this.state.pushsToShow)
														.map((eventSession, index) => 
												
															<ObjPushEventSession 
																key={index}
																eventSession={eventSession} 
																pageEventDetail={PageEventDetail}
															/>

											)}
											{(this.state?.eventsFiltered?.flatMap(event=> event.Sessions).length > this.state.pushsToShow) && 
												<div className="ctn_cta">
													<button className="obj_cta" type="button" onClick={() => this.showMorePushs()}>Plus de formations</button>
												</div>
											}
										</>
							} 
						</section>
					</div>
				</div>
			</section>
		</>)
	}
}

export default withTranslation()(PageEvents)