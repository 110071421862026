import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next'; 
import config from '../../temp/config';
import axios from 'axios'; 
import { useFormik, Field, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import ModalProduitConcernes from '../Modal/ModalProduitConcernes';
import DefaultModal from '../Modal/DefaultModal';
import SingleFileUpload from '../../utilities/FrontFunctions/FormElements/SingleFileUpload';

const ObjAsideChallengeADeclarer = ({ participation, t }) => {

	const [isChalengeVariable,setIsChalengeVariable] = useState(!!participation?.challenge?.Challenge_EvolutionPercent);
	const [initialValues, setInitialValues] = useState({
		FilesUpload_N_0: '',
	});
	const [validationSchema, setValidationSchema] = useState({
		FilesUpload_N_0: Yup.mixed().required(),
	});
	const [uploadList, setUploadList] = useState({
		currentYear: [{ fieldName: 'FilesUpload_N_0' }],
		previousYear: [{ fieldName: 'FilesUpload_N1_0' }],
	});
	const [apiError, setApiError] = useState(false);
	const [apiErrorMsg, setApiErrorMsg] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);

	const modalRef = useRef(null);

	useEffect(() => {
		const challenge = participation.Challenge;
		const isNewChalengeVariable = challenge.Challenge_EvolutionPercent;

		let newInitialValues = {
			...initialValues,
			ChallengeId: challenge.Challenge_Id,
			...(isNewChalengeVariable && { FilesUpload_N1_0: '' }),
		};
		let newValidationSchema = {
			...validationSchema,
			...(isNewChalengeVariable && { FilesUpload_N1_0: Yup.mixed().required() }),
			ChallengeId: Yup.number().required(),
		};

		challenge.Conditions.forEach((condition, index) => {
			newInitialValues = {
				...newInitialValues,
				[`Declarations_${index}_CondId`]: condition.ChallengeCondition_Id,
				[`Declarations_${index}_Current`]: '',
				...(isNewChalengeVariable && { [`Declarations_${index}_Anterior`]: '' }),
			};
			newValidationSchema = {
				...newValidationSchema,
				[`Declarations_${index}_CondId`]: Yup.number().required(),
				[`Declarations_${index}_Current`]: Yup.number().required(),
				...(isNewChalengeVariable && { [`Declarations_${index}_Anterior`]: Yup.number().required() }),
			};
		});
		setIsChalengeVariable(isNewChalengeVariable);
		setInitialValues(newInitialValues);
		setValidationSchema(newValidationSchema);

	}, []);

	const handleAddUpload = (isCurrentYear) => {
		const newFieldName = isCurrentYear ? `FilesUpload_N_${uploadList.currentYear.length}` : `FilesUpload_N1_${uploadList.previousYear.length}`;
		const newUploadList = isCurrentYear ? 
			{ ...uploadList, currentYear: [...uploadList.currentYear, { fieldName: newFieldName }] } :
			{ ...uploadList, previousYear: [...uploadList.previousYear, { fieldName: newFieldName }] };
		
		setUploadList(newUploadList);
		setInitialValues({ ...initialValues, [newFieldName]: '' });
	};

	const formik = useFormik({
		initialValues,
		validationSchema: Yup.object().shape(validationSchema),
		enableReinitialize: true,
		onSubmit: (values) => {
			document.body.classList.add('is-loading');

			const formData = new FormData();
			Object.entries(values).forEach(([key, value]) => {
				const inputNameList = key.split('_');
				if (inputNameList.length <= 1) {
					formData.append(key, value);
				} else if (inputNameList.length === 2) {
					formData.append(`${inputNameList[0]}[${inputNameList[1]}]`, value);
				} else {
					formData.append(`${inputNameList[0]}[${inputNameList[1]}].${inputNameList[2]}`, value);
				}
			});

			axios.post(`${config.sitecoreApiHost}/apiaox/Challenge/PostChallengeDeclaration`, formData, {
				withCredentials: true,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((result) => {
				document.body.classList.remove('is-loading');
				setFormSubmitted(result.data.IsValid);
				setApiError(!result.data.IsValid);
				setApiErrorMsg(result.data.ExceptionMessage);
				if (result.data.IsValid) {
					modalRef.current.openModal();
				}
			})
			.catch(() => {
				document.body.classList.remove('is-loading');
				setApiError(true);
				setApiErrorMsg(t('DefaultServerError'));
			});
		},
	});

	return (
		<div className="obj_saisie-vente">
			<div className="ctn_form">
				<FormikProvider value={formik}>
					<Form>
						<div className="obj_form">
							<h3 className="t_title">Déclaration de mes ventes</h3>

							{participation.Challenge.Conditions.map((condition, index) => (
								<div className="obj_saisie-vente-item" key={index}>
									<p className="t_desc">
										{condition.ChallengeCondition_Name}
										{condition.ChallengeCondition_EnableVariableGain && " ( condition variable )"}
									</p>
									{condition.Produits.length > 0 && (
										<>
											<button
												type="button"
												className="obj_btn-popin-detail"
												onClick={() => modalRef.current.openModal()}
											>
												<span>voir les produits concernés</span>
												<IconSVG icon="arrowDown" />
											</button>
											<ModalProduitConcernes
												produits={condition.Produits}
												onRef={(ref) => (modalRef.current = ref)}
											/>
										</>
									)}
									<div className="obj_saisie-vente-ctn_input">
										<div className="ctn_row">
											<div className="ctn_input">
												<p className="t_label">ventes année {new Date(participation.Challenge.Challenge_EndDate).getFullYear()}*</p>
												<Field
													as="input"
													type="text"
													name={`Declarations_${index}_Current`}
													placeholder="000"
													maxLength={6}
													className={formik.touched[`Declarations_${index}_Current`] && formik.errors[`Declarations_${index}_Current`] && 'error'}
												/>
											</div>
											{isChalengeVariable && (
												<div className="ctn_input">
													<p className="t_label">ventes année {new Date(participation.Challenge.Challenge_EndDate).getFullYear() - 1}*</p>
													<Field
														as="input"
														type="text"
														name={`Declarations_${index}_Anterior`}
														placeholder="000"
														maxLength={6}
														className={formik.touched[`Declarations_${index}_Anterior`] && formik.errors[`Declarations_${index}_Anterior`] && 'error'}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							))}

							<h3 className="t_title">Importer mes listings</h3>

							<p className="t_desc">
								Si votre challenge concerne une évolution sur deux années différentes, vous devrez nous transmettre les fichiers de ventes de référence sur cette période. <br />
								Attention : sans l'intégralité de ces fichiers, votre déclaration sera refusée et vous devrez déclarer à nouveau votre challenge.
							</p>

							<div className="ctn_add-file">
										<h4 className="t_title">Importer mes fichiers {new Date(participation.Challenge.Challenge_EndDate).getFullYear()}</h4>
									</div>
							<>
								{uploadList.currentYear.map((uploadItem, index) => (
									<div className="ctn_file-upload" key={index}>
										<SingleFileUpload
											fieldName={uploadItem.fieldName}
											labelText=" "
											setFieldValue={formik.setFieldValue}
											setFieldTouched={formik.setFieldTouched}
											errors={formik.errors}
											touched={formik.touched}
											formatTypes={['image/jpeg', 'image/png', 'application/pdf']}
											formatErrorText="Format de fichiers non permis."
										/>
										<div className="i_add">
											<IconSVG icon="importUpload" />
											<span >Cliquez pour importer un fichier {new Date(participation.Challenge.Challenge_EndDate).getFullYear()}{index==0 && '*'}</span>
										</div>
										{/* <button type="button" onClick={() => this.handleRemoveUpload(uploadItem)}>Supprimer le fichier</button> */}
									</div>
								))}

								{uploadList.currentYear.length<4 &&
									<div  className="ctn_add-file" >
										<button type="button" className="bt_add-file" onClick={() => handleAddUpload(true)}>
											Ajouter un autre fichier {new Date(participation.Challenge.Challenge_EndDate).getFullYear()} à importer
											<span className="i_plus"></span>
										</button>
									</div>
								}
							</>

							{isChalengeVariable && (
								<>
									<div className="ctn_add-file">
										<h4 className="t_title">Importer mes fichiers {new Date(participation.Challenge.Challenge_EndDate).getFullYear() - 1}</h4>
									</div>
									<>
										{uploadList.previousYear.map((uploadItem, index) => (
											<div className="ctn_file-upload" key={index}>
												<SingleFileUpload
													fieldName={uploadItem.fieldName}
													labelText=" "
													setFieldValue={formik.setFieldValue}
													setFieldTouched={formik.setFieldTouched}
													errors={formik.errors}
													touched={formik.touched}
													formatTypes={['image/jpeg', 'image/png', 'application/pdf']}
													formatErrorText="Format de fichiers non permis."
												/>
												<div className="i_add">
													<IconSVG icon="importUpload" />
													<span>Cliquez pour importer un fichier {new Date(participation.Challenge.Challenge_EndDate).getFullYear()-1}{index==0 && '*'}</span>
												</div>
												{/* <button type="button" onClick={() => this.handleRemoveUpload(uploadItem)}>Supprimer le fichier</button> */}
											</div>
										))}

										{uploadList.previousYear.length<4 &&
											<div className="ctn_add-file">
												<button type="button" className="bt_add-file" onClick={() => handleAddUpload(false)}>
													Ajouter un autre fichier {new Date(participation.Challenge.Challenge_EndDate).getFullYear()-1} à importer
													<span className="i_plus"></span>
												</button>
											</div>
										}
									</>
								</>
							)}

							<p className="t_mention">Formats acceptés : jpeg, png, pdf.</p>

							<p className="t_mention">{t("Connexion_mandatoryText")}</p>

							<div className="ctn_cta">
								<button className="obj_cta" type="submit">Envoyer</button>
							</div>

							{formik.submitCount > 0 && !formik.isValid && (
								<p className="t_error mb_30">Le formulaire est incomplet</p>
							)}

							{apiError && (
								<p className="t_error mb_30 fade-in">{apiErrorMsg}</p>
							)}
						</div>
					</Form>
				</FormikProvider>

				<DefaultModal
					onRef={(ref) => (modalRef.current = ref)}
					htmlContent={
						<>
							<p className="t_title">Votre déclaration a bien été transmise.</p>
							<p className="t_main"></p>
							<div className="ctn_cta">
								<button className="obj_cta" type="button" onClick={() => window.location.reload(false)}>fermer</button>
							</div>
						</>
					}
				/>
			</div>
		</div>
	);
}

export default withTranslation()(ObjAsideChallengeADeclarer);
