import React, { Component } from 'react'
import FilterInput from "../../utilities/FrontFunctions/FormElements/FilterInput";
import { useEffect } from 'react';
import { useState } from 'react';

export default function ModalContentChallengeARepartir({membres, usersSelected, onAdd, onClose}){

    const [membresFiltered, setMembresFiltered] = useState(membres);


    return(
        <div className="ctn_list_participants_select fade-in">
            <div className="wrapper_list_participants_select">

                
                <p>Ajout d'un bénéficiaire</p>

                <FilterInput
                    label="Rechercher"
                    id="filter-user"
                    name="filter_user"
                    placeholder="Nom du bénéficiaire"
                    onChange={(text) => {
                        setMembresFiltered(
                          membres.filter((mem) => mem.Name.toLowerCase().includes(text.toLocaleLowerCase())),
                        );
                      }}
            
                />
                <ul>
                    {membresFiltered.map((membre, index) => {
                        const alreadySelected = usersSelected.find((user) => user.Id === membre.Id)? true: false;
                        const IsRepartitable = membre.IsRepartitable;
                       
        
                    return (
                        <li key={index}>
                            <button
                                type="button"
                                onClick={() => onAdd(membre)}
                                disabled={alreadySelected}
                            >
                                {membre.Name}
                                {
                                    !IsRepartitable && <p className="t_error">Le n° de sécurité sociale doit être renseigné pour ajouter ce bénéficiaire.</p>
                                }
                                {
                                   !alreadySelected && IsRepartitable && <span className="t_add">Ajouter <span className="i_plus"></span></span>
                                }
                                
                            </button>
                        </li>
                    )})}
                </ul>
                <button className="bt_list_participants_close" type="button" onClick={() => onClose()}></button>
            </div>
            <div className="ctn_list_participants_overlay" onClick={() => onClose()}></div>
        </div>
    ) 
};
